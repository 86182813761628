import $ from 'jquery';

export default function () {

    var menuOuvert = false;

	$(window).scroll(function(){
		$('.c-header-mobile__panel').removeClass('active');
	});

	$('.c-header-mobile__toggle').click(function(event){
		event.stopPropagation();
		if(!menuOuvert){
			$('.c-header-mobile__panel').addClass('active');
			menuOuvert = true;
		}
		else{
			$('.c-header-mobile__panel').removeClass('active');
			menuOuvert = false;
		}
		event.stopPropagation();
	});

	$('.c-header-mobile__close').click(function(){
		if(menuOuvert){
			$('.c-header-mobile__panel').removeClass('active');
			menuOuvert = false;
		}
	}); 

}
