import $ from 'jquery';

export default function () {

	function fullHeight() {
		var top = $('.c-top').outerHeight();
		var header = $('.c-header').outerHeight();
		var footer = $('.c-footer').outerHeight();
		var sub = top + header + footer;
		var browserHeight = $(window).height();

		$('.c-not-found').css('height', browserHeight - sub + 'px' );
	};

	fullHeight();

	$(window).resize(function() {

		fullHeight();
	});

}