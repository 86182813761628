import $ from 'jquery';
import simpleLightbox from 'simple-lightbox';
import { translations } from '../translations.js';

export default function () {

	var hopeboots, // Global variable that will receive the JSON for the builder
		colors, // Global variable that will receive the JSON for the builder
		furs, // Global variable that will receive the JSON for the builder
		beadings, // Global variable that will receive the JSON for the builder
		currentModel, // Contains the selected boot Model
		currentColor, // Contains the selected boot Color
		currentFur, // Contains the selected Fur
		currentBeadings, // Contains the select Beading theme, can be undefined.
		currentStep = 1, // Where to init the builder. Should always be 1
		lastStep = 5, // How many steps are there
		$stepButtons = $('.js-builder-step-btn'), // Timeline buttons to go to the previous step
		$nextStepButton = $('.js-next-step'),  // Buttons to go to the next step. One per step
		$notice = $('.js-builder-notice'), // Notice box for alerts
		$history = $('.js-builder-history'), // Notice box for alerts
		$closeNotice = $('#js-builder-notice-close'), // Close button for notices
		$closeHistory = $('#js-builder-history-close'), // Close button for notices
		$stepTwoImage = $('.js-step-two-image'), //Rendered image for step two
		$stepThreeImage = $('.js-step-three-image'), //Rendered image for step three 
		$stepThreeDesc = $('.js-step-three-fur-desc'), //Get fur description for step three
		$stepFourImage = $('.js-step-four-image'), //Rendered image for step four
		$stepFiveImage = $('.js-step-five-image'); //Rendered image for step five

	// Load the JSON file with products and attributes
	$.getJSON(hopeboots_json, function(data){
		// Store the JSON in the Hopeboots variable.

		hopeboots = data[0].products;
		colors = data[0].colors;
		furs = data[0].furs;
		beadings = data[0].beadings;

		// Init the builder. Magic happens here.
		build();
	});

	// If a user clicks on a button in the timeline, take him to that step
	$stepButtons.on('click', function(){
		goToStep($(this).attr('data-step'));
	});

	// If a user clicks on a next step button, go to the specified step
	$nextStepButton.on('click', function(){
		goToStep(parseInt($(this).closest('.js-builder-step').attr('data-step')) + 1);
	});

	$(document).on('click', '.js-read-beading-history', function(event){
		event.stopPropagation();
		event.preventDefault();

		let beading = $(this).attr('data-beading-index');


		$history.addClass('opened').find('p').text(`${beadings[beading].description}`);
	});

	$closeNotice.click(function(event){
		event.preventDefault();
		$notice.removeClass('opened');
	})

	$closeHistory.click(function(event){
		event.preventDefault();
		$history.removeClass('opened');
	})

	// Checks for previous step completion when going to the next step. This makes
	// sure no user will end up with an incomplete build
	function checkStepCompletion($step) {

		if($step == 1) { // Test for step one (model selection)
			if(currentModel == undefined){
				notice(translations[language]['notice-model']);
				return false;
			}
			return true;
		}

		else if($step == 2) { // Test for step two (color selection)
			if(currentColor == undefined){
				notice(translations[language]['notice-color']);
				return false;
			}
			return true;
		}

		else if($step == 3) { // Test for step three (fur selection)
			if(currentFur == undefined){
				notice(translations[language]['notice-fur']);
				return false;
			}
			return true;
		}

		else {	//Assume that any other step doesn't need validation
			return true; 
		}

	}

	// This function send a notice to the user for any error in a lightbox
	function notice($message) {
		
		$notice.addClass('opened');

		$notice.find('p').text($message);
	}

	function getImageSrc(model, color = null, fur = null) {

		var base = builder_images + 'crops/';

		if(model) {
			base += model;
		}

		if(color) {
			base += '-' + color;
		}

		if(fur) {
			base += '-' + fur;
		}

		base += '.jpg';

		return base;
	}

	// Build the current step to display. This function loads models, colors, furs, etc and outputs
	// the correct HTML and listeners from step 1 until the final build.
	function build() {

		if(currentStep == 1) {
			// Get the container for the models
			let $models = $('.js-builder-models');		
			// Remove the current selected model if any
			currentModel = undefined;
			$models.empty();
			// Loop through the different models
			$.each( hopeboots, function( key, model ) {
				// Create a new model item
				let src = getImageSrc(model.slug);

				let $model = $(`
					<li>
						<div class="c-builder__img">
							<img src="${src}">
						</div>
						<h3>${model.name}</h3>
					</li>
				`).on('click', function(){
					// If a user clicks on an item, make it the active one
					$models.find('li').removeClass('active');
					$(this).addClass('active');
					// Set the current model
					currentModel = key;	
				});
				// Append the new model
				$models.append($model);

			});

		} else if(currentStep == 2) {
			// Get the container for the colors
			let $colors = $('.js-builder-colors');
			
			var src = getImageSrc(hopeboots[currentModel].slug);
			$stepTwoImage.attr('src',src);

			// Remove the current selected color if any
			currentColor = undefined;
			$colors.empty();

			//Loop through the dirrent colors for the selected model
			$.each( hopeboots[currentModel].colors, function( key, color ) {

				var name = colors[color.slug].name;
				var hex = colors[color.slug].hex;

				//If the color is white, give it a border-color to make it visible
				if(hex !== "#ffffff" && hex !== "#fff") {
					var border = `border-color:${hex};`;
				} else {
					var border = ``;
				}
				// Create a new color item
				let $color = $(`
					<li>
						<div>
							<div class="c-builder__color-visual" style="background-color:${hex};${border};"></div>
							<span>${name}</span>
						</div>
					</li>
				`).on('click', function(){
					// If a user clicks on an item, make it the active one
					$colors.find('li').removeClass('active');
					$(this).addClass('active');
					// Set the current color
					currentColor = key;

					src = getImageSrc(hopeboots[currentModel].slug, color.slug);
					$stepTwoImage.attr('src',src);

				});

				$colors.append($color);

			});

		} else if(currentStep == 3) {
			// Get the container for the fur
			let $furs = $('.js-builder-furs');

			var src = getImageSrc(
				hopeboots[currentModel].slug,
				hopeboots[currentModel].colors[currentColor].slug
			);

			$stepThreeImage.attr('src',src);

			// Remove the current selected color if any
			currentFur = undefined;
			$furs.empty();
			//Loop through the different colors for the selected model
			$.each( hopeboots[currentModel].colors[currentColor].furs , function( key, fur ) {

				var name = furs[key].name;
				var texture = furs[key].texture;
				var description = furs[key].description;

				// Create a new fur item
				var $fur = $(`
					<li>
						<div>
							<div class="c-builder__fur-visual" style="background-image:url('${builder_images}textures/${texture}');"></div>
							<span>${name}</span>
						</div>
					</li>
				`).on('click', function(){
					// If a user clicks on an item, make it the active one
					$furs.find('li').removeClass('active');
					$(this).addClass('active');
					// Set the current color
					currentFur = key;

					src = getImageSrc(
						hopeboots[currentModel].slug,
						hopeboots[currentModel].colors[currentColor].slug,
						key
					);

					$stepThreeImage.attr('src',src);
					$stepThreeDesc.html('<h3>'+name+'</h3>' + '<p>'+description+'</p>');

				});
				// Append the fur to the list
				$furs.append($fur);
			});

		} else if(currentStep == 4) {
			// Get the container for the colors
			let $beadings = $('.js-builder-beadings');
			let $beadingTitle = $('.js-builder-current-beading');
			let $beadingIcons = $('.js-builder-beadings-list');
			let $beadingExamples = $('.js-builder-current-beading-examples');
			let $beadingExamplesContainer = $('.c-builder__img-examples');
			let $bootOverlayBadges = $('.c-builder__badges');
			// Remove the current selected beading if any
			currentBeadings = undefined;
			$beadings.empty();

			var src = getImageSrc(
				hopeboots[currentModel].slug,
				hopeboots[currentModel].colors[currentColor].slug,
				currentFur
			);

			$stepFourImage.attr('src',src);
			$stepFiveImage.attr('src',src);

			// Loop through the different beadings for the selected model
			$.each( beadings, function( key, beading ) {
				// Create a new beading item

				var $beading = $(`
					<li>
						<div>
							<img src="${builder_images}beadings/${beading.icon}" alt="">
							<div class="c-builder__beading-history">
								<h3>${beading.name}</h3>
								<a href="#" data-beading-index="${key}" class="js-read-beading-history">${translations[language]['read-beading-history']}</a>
							</div>
						</div>
					</li>
				`).on('click', function(event){

					// If a user clicks on an item, make it the active one
					$beadings.find('li').removeClass('active');
					$(this).addClass('active');
					// Set the current color
					currentBeadings = key;
					// Set the current beading title
					$beadingTitle.text(beading.name);
					// Show the three different beadings for the selected beadings theme
					$beadingIcons.html(`
						<li><span>1</span><img src="${builder_images}beadings/${beading.beading_one}"></li>
						<li><span>2</span><img src="${builder_images}beadings/${beading.beading_two}"></li>
						<li><span>3</span><img src="${builder_images}beadings/${beading.beading_three}"></li>
					`);

					// Empty out all the examples before loading the new ones
					$beadingExamples.empty();
					// Get all the example images for the selected beading theme
					$.each( hopeboots[currentModel].colors[currentColor].furs[currentFur] , function( example, url ) {

						// Generate the example link item with the image
						var $link = $(`
							<a href="${builder_images}crops/${url}">
								<img src="${builder_images}crops/${url}"/>
							</a>`
						);
						// Initiale simpleLightBox 
						$link.simpleLightbox();
						// Append it to the examples container
						$beadingExamples.append($link);	
					});
					// Make sure to make the beadings details visible
					$beadingIcons.css('opacity',1);
					$bootOverlayBadges.css('opacity',1);
					$beadingExamplesContainer.css('opacity',1);

				});
				// Append the beading to the list
				$beadings.append($beading);
			});

			// Create a clickable item for users who don't want any beadings
			var $noBeadings = $(`<li>
				<div>
					<img src="${builder_images}beadings//aucun-perlage.png" alt="">
						<div class="c-builder__beading-history">
							<h3>${translations[language]['no-beading']}</h3>
						</div>
					</div>
				</li>
			`).on('click', function(){
				// If a user clicks on an item, make it the active one
				$beadings.find('li').removeClass('active');
				currentBeadings = undefined;
				// Set the current color
				$(this).addClass('active');
				// Make sure to make the beadings details visible
				$beadingIcons.css('opacity',0);
				$bootOverlayBadges.css('opacity',0);
				$beadingExamplesContainer.css({'opacity':'0'}, function(){
					$(this).empty();
				});
			})
			// Append the "No beading" item
			$beadings.append($noBeadings);
			// By default and on build of this step, select the first beading
			$beadings.find('li').first().trigger('click');

		} else if(currentStep == 5) {

			let $model = $('.js-step-5-model');
			let $colorBox = $('.js-step-5-color-box');
			let $color = $('.js-step-5-color');
			let $furBox = $('.js-step-5-fur-box');
			let $fur = $('.js-step-5-fur');
			let $beading = $('.js-step-5-beading');
			let $price = $('.js-step-five-price');
			let $addToCard = $('.js-step-5-add-to-card');
			let $sizeSelector = $('.js-step-5-size');

			var modelName = hopeboots[currentModel].name;
			var colorHex = colors[hopeboots[currentModel].colors[currentColor].slug].hex;
			var colorName = colors[hopeboots[currentModel].colors[currentColor].slug].name;
			var furName = furs[currentFur].name;
			var furTexture = furs[currentFur].texture;

			

			$addToCard.show();
			$addToCard.attr("disabled", true);
			$addToCard.attr('href','');

			var selectedBeading = "";

			if(currentBeadings !== undefined) {
				selectedBeading = beadings[currentBeadings].name;
			} else {
				selectedBeading = translations[language]['no-beading-attribute-value'];
			}

			$.post(
				ajaxurl, 
			    {
			        'action': 'getVariationId',
			        'broderie': selectedBeading,
			        'fourrure': furName,
			        'couleur': colorName,
			        'modele': modelName,
			        'taille': $sizeSelector.val()
			    }, 
			    function(link){
			        if(link=="error") {
			    		$addToCard.fadeOut(300);
			    		notice(translations[language]['product-no-exist']);
			    	} else {
			    		$addToCard.fadeIn(300);
			    		$addToCard.attr("disabled", false);
			        	$addToCard.attr('href',link);
			    	}
			    }
			);

			$sizeSelector.change(function() {

				var $sizeSelector = $('.js-step-5-size');

				$addToCard.attr("disabled", true);
				$addToCard.attr('href','');

				$.post(
					ajaxurl, 
				    {
				        'action': 'getVariationId',
				        'broderie': selectedBeading,
				        'fourrure': furName,
				        'couleur': colorName,
				        'modele': modelName,
				        'taille': $sizeSelector.val()
				    }, 

				    function(link){
				    	if(link=="error") {
				    		$addToCard.fadeOut(300);
				    		notice(translations[language]['product-no-exist']);
				    	} else {
				    		$addToCard.fadeIn(300);
				    		$addToCard.attr("disabled", false);
				        	$addToCard.attr('href',link);
				    	}
				        
				    }
				);
				
			})

			$model.text(hopeboots[currentModel].name);
			$color.text(colorName);

			if(colorHex !== "#ffffff" && colorHex !== "#fff") {
				$colorBox.css('border-color', colorHex);
			} else {
				$colorBox.css('border-color','');
			}

			$colorBox.css('background-color', colorHex);

			$fur.text(furName);
			$furBox.css('background-image',`url('${builder_images}textures/${furTexture}')`);

			if(currentBeadings !== undefined) {

				$beading.fadeIn(100);

				var beadingName = beadings[currentBeadings].name;
				var beadingIcon = beadings[currentBeadings].icon;
				var beadingDescription = beadings[currentBeadings].description;

				$beading.find('h3').text(beadingName);
				$beading.find('p').text(beadingDescription);
				$beading.find('img').attr('src',`${builder_images}beadings/${beadingIcon}`);

				if(language == 'fr') {
					$price.text('1195.00$');
				} else {
					$price.text('$ 1195,00');
				}

			} else {
				$beading.hide();

				if(language == 'fr') {
					$price.text('895.00$');
				} else {
					$price.text('$ 895,00');
				}
			}

		}

		// Disable all the steps after the current step
		var i = lastStep;
		while(i > currentStep) {
			getStepBtn(i).prop('disabled', true);
			i--;
		}
		// Add class on the current step button
		var $currentStepButton = getStepBtn(currentStep);
		$stepButtons.not($currentStepButton).removeClass('current');
		$currentStepButton.addClass('current').prop('disabled', false);

	}

	// Handler for the buttons to change step
	function getStepBtn($step) {
		return $(`.js-builder-step-btn[data-step="${$step}"]`);
	}

	// Manages the current visible step container.
	function goToStep($step) {
		
		if($step > 1) {
			// Check that the previous step was completed correctly
			if(checkStepCompletion($step - 1)) {
				// If validation is successfull, change the current step
				currentStep = $step;
				// Disable the step container
				$('.js-builder-step').not(`[data-step="${$step}"]`).hide()
				$(`.js-builder-step[data-step="${$step}"]`).fadeIn(500);
				build();
			}

		} else {
			// Check that the first step was completed correctly
			if(checkStepCompletion(1)){
				// If validation is successfull, change the current step
				currentStep = $step;
				// Disable the step container
				$('.js-builder-step').not(`[data-step="1"]`).hide()
				$(`.js-builder-step[data-step="1"]`).fadeIn(500);
				build();
			}
		}	
	}

}