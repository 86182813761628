var translations = []; //Array containing all translations

// Declare any french translations used in the builder here
translations['fr'] = [];
translations['fr']['size-chart'] = 'Voir le guide des pointures';
translations['fr']['add-to-list'] = 'Ajouter à la liste de souhaits';
translations['fr']['remove-from-list'] = 'Retirer de la liste de souhaits';
translations['fr']['no-beading'] = 'Aucun perlage';
translations['fr']['no-beading-attribute-value'] = 'Sans broderie';
translations['fr']['product-no-exist'] = 'Désolé, ce produit n\'est pas disponible';
translations['fr']['notice-model'] = 'Sélectionnez un modèle';
translations['fr']['notice-color'] = 'Sélectionnez une couleur';
translations['fr']['notice-fur'] = 'Sélectionnez une fourrure';
translations['fr']['read-beading-history'] = 'Lire l\'histoire';
translations['fr']['quantity'] = 'Quantité';

// Declare any english translations used in the builder here
translations['en'] = [];
translations['en']['size-chart'] = 'View size chart';
translations['en']['add-to-list'] = 'Add to wishlist';
translations['en']['remove-from-list'] = 'Remove from wishlist';
translations['en']['no-beading'] = 'No beadings';
translations['en']['no-beading-attribute-value'] = 'No beading';
translations['en']['product-no-exist'] = 'Sorry, this product is currently unavailable';
translations['en']['notice-model'] = 'Select a model';
translations['en']['notice-color'] = 'Select a color';
translations['en']['notice-fur'] = 'Select a fur';
translations['en']['read-beading-history'] = 'Discover the history';
translations['en']['quantity'] = 'Quantity';

export { translations }