import $ from 'jquery';

export default function () {


		var $submitButton = $('.mc-embedded-subscribe');

		$submitButton.click(function(event){
			event.preventDefault();

			var $email = $(this).closest('form').find('.mce-EMAIL');
			var email = $email.val();
			var success = $('.mce-success-response');
			var error = $('.mce-error-response');
			var validator = $(this).closest('form').find('.mce-validator').val();
			
			if(validator!=='') {

				error.hide();
	        	success.slideDown(300);
	        	$email.val('');

			} else {

				$.post(
					ajaxurl, 
				    {
				        'action': 'submitMailchimpNewUser',
				        'email': email
				    }, 
				    function(response){
				        if(response==='true') {
				        	error.hide();
				        	success.slideDown(300);
				        	$email.val('');
				        } else {
				        	success.hide();
				        	error.slideDown(300);
				        }
				    }
				);
			}
		});

}