import $ from 'jquery';
import simpleLightbox from 'simple-lightbox';
import { translations } from '../translations.js';

export default function () {

	var hasVariableSize = $('#pa_grandeur').length > 0;

	var chartUrlMen = assets + "/images/size-chart-men-"+language+".jpg";
	var chartUrlWomen = assets + "/images/size-chart-women-"+language+".jpg";

	var $productMeta = $('.product_meta');

	if(hasVariableSize) {
		$productMeta.after($(`
			<a class="view-size-chart js-view-size-chart" href="${chartUrlWomen}"><i class="fa fa-expand" aria-hidden="true"></i> ${translations[language]['size-chart']}</a>
			<a style="display: none;" class="view-size-chart js-view-size-chart" href="${chartUrlMen}"><i class="fa fa-expand" aria-hidden="true"></i> ${translations[language]['size-chart']}</a>
		`));
	}

	$('.js-view-size-chart').simpleLightbox();
}