import $ from 'jquery';
import simpleLightbox from 'simple-lightbox';
import { translations } from '../translations.js';

export default function () {

	$('.c-product-single .quantity').prepend(`<span>${translations[language]['quantity'] }</span>`);
	$('.c-product-single .single_add_to_cart_button').prepend('<i class="fa fa-shopping-cart" aria-hidden="true"></i>');

	console.info($('.woocommerce-product-gallery__image a'));
	
	$('.woocommerce-product-gallery__image a').simpleLightbox();

}