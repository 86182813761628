import $ from 'jquery';

export default function () {

	var $window = $(window);
	
	function heroHeight() {

		var wHeight = $(window).height();
		var topBarHeight = $('.c-top').outerHeight();
		var headerHeight = $('.c-header').outerHeight();
		var heightReduction = (topBarHeight + headerHeight);

		$('.c-hero').height(wHeight - heightReduction);
	}

	heroHeight();

	$window.resize(function() {

		heroHeight();

	})
}