import $ from 'jquery';
import { translations } from '../translations.js';

export default function () {

	$(document).on('click', '.js-add-to-wishlist', function(event){

		event.preventDefault();

		$(this)
			.removeClass('js-add-to-wishlist')
			.addClass('js-remove-from-wishlist')
			.addClass('is-in-wishlist');
		$.ajax({
	  		url: wishlist_url + '?add=' + $(this).attr('data-product-id')
		});

	});

	$(document).on('click', '.js-remove-from-wishlist', function(event){

		event.preventDefault();

		$(this)
			.addClass('js-add-to-wishlist')
			.removeClass('js-remove-from-wishlist')
			.removeClass('is-in-wishlist');
		$.ajax({
	  		url: wishlist_url + '?remove=' + $(this).attr('data-product-id')
		});

	});

	var $wishlistText = $('.c-wishlist__action-text');

	if($('.c-product-single .c-wishlist').hasClass('is-in-wishlist')) {
		$wishlistText.html(translations[language]['remove-from-list']);
	} else {
		$wishlistText.html(translations[language]['add-to-list']);
	}

	$('.c-product-single .c-wishlist').click(function() {

		var $wishlistText = $('.c-wishlist__action-text');

		$wishlistText.html(translations[language]['remove-from-list']);

		if($(this).hasClass('is-in-wishlist')) {
			$wishlistText.html(translations[language]['add-to-list']);
		}
	});

}