import $ from 'jquery';

export default function() {

	$(document).scroll(function() {

		var h = $(document).scrollTop();

		// Philosophie selectors

		var $philo1 = $('.philo-one'),
			$philo2 = $('.philo-two'),
			$philo3 = $('.philo-three'),
			$philo4 = $('.philo-four'),
			$philo5 = $('.philo-five');

		// À propos selectors

		var $about1 = $('.about-one'),
			$about2 = $('.about-two');


		// À propos selectors

		var $hopeboots1 = $('.hopeboots-one'),
			$hopeboots2 = $('.hopeboots-two'),
			$hopeboots3 = $('.hopeboots-three'),
			$hopeboots4 = $('.hopeboots-four');


		

		if($(window).width() >= 992) {

			// Philosophie image parallax
			$philo1.css('transform', 'translateY(-' + h / 4 + 'px)');
			$philo1.css('-webkit-transform', 'translateY(-' + h / 4 + 'px)');

			$philo2.css('transform', 'translateY(-' + h / 6 + 'px)');
			$philo2.css('-webkit-transform', 'translateY(-' + h / 6 + 'px)');

			$philo3.css('transform', 'translateY(-' + h / 6 + 'px)');
			$philo3.css('-webkit-transform', 'translateY(-' + h / 6 + 'px)');

			$philo4.css('transform', 'translateY(-' + h /  6 + 'px)');
			$philo4.css('-webkit-transform', 'translateY(-' + h / 6 + 'px)');

			$philo5.css('transform', 'translateY(' + h /  8 + 'px)');
			$philo5.css('-webkit-transform', 'translateY(' + h / 8 + 'px)');

			// À propos image parallax
			$about1.css('transform', 'translateY(-' + h / 4 + 'px)');
			$about1.css('-webkit-transform', 'translateY(-' + h / 4 + 'px)');

			$about2.css('transform', 'translateY(-' + h / 6 + 'px)');
			$about2.css('-webkit-transform', 'translateY(-' + h / 6 + 'px)');

			// À propos image parallax

			$hopeboots1.css('transform', 'translateY(-' + h / 6 + 'px)');
			$hopeboots1.css('-webkit-transform', 'translateY(-' + h / 6 + 'px)');

			$hopeboots2.css('transform', 'translateY(-' + h / 8 + 'px)');
			$hopeboots2.css('-webkit-transform', 'translateY(-' + h / 8 + 'px)');

			$hopeboots3.css('transform', 'translateY(-' + h / 8 + 'px)');
			$hopeboots3.css('-webkit-transform', 'translateY(-' + h / 8 + 'px)');

			$hopeboots4.css('transform', 'translateY(' + h /  8 + 'px)');
			$hopeboots4.css('-webkit-transform', 'translateY(' + h / 8 + 'px)');
		}

	})
}