import './modules/helpers';
import start from './modules/router';
import mobileNav from './modules/mobileNav';
import loader from './modules/loader';
import scroller from './modules/scroller';
import hero from './modules/hero';
import bootBuilder from './modules/bootBuilder';
import wishList from './modules/wishList';
import mailChimp from './modules/mailChimp';
import youtube from './modules/youtube';
import wooFunctions from './modules/wooFunctions';
import slick from './modules/slick';
import notFound from './modules/notFound';
import guideDesTailles from './modules/guideDesTailles';
import woocommerceProductFilter from './modules/woocommerceProductFilter';
import parallax from './modules/parallax';
import imageTranslate from './modules/imageTranslate';

bootBuilder();
mobileNav();
loader();
scroller();
hero();
wishList();
mailChimp();
youtube();
wooFunctions();
slick();
notFound();
guideDesTailles();
woocommerceProductFilter();
parallax();
imageTranslate();