import $ from 'jquery';

export default function () {

	$('.js-filter-woo-products').change(function(){

		var term = $(this).attr('data-term-id');
		var $results = $('.js-filter-woo-products-results');
		var orderby = $('.js-filter-woo-products').val();
		var order = $('.js-filter-woo-products option:selected').attr('data-order');

		$results.addClass('loading');

	    $.post(
			ajaxurl, 
		    {
		        'action': 'getProducts',
		        'category': term,
		        'orderby': orderby,
		        'order': order
		    }, 
		    function(response){
		        $results.html(response);
		        $results.removeClass('loading');
		    }
		);

	});

}