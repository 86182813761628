import $ from 'jquery';
import Slick from 'slick-carousel';

export default function () {

	var philoSlider = $('.js-philosophie-slider').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true
	});

	$('.js-philosophie-slider-goto').click(function(e) {
		e.preventDefault();

		var slide = $(this).attr('data-slide');

		philoSlider.slick('slickGoTo', slide);
	});
}